<template>
  <v-row>
    <notifications
      group="public"
      position="bottom center"
      :style="{ marginBottom: '2px' }"
    />
    <h1 slot="tableTitle" class="font-weight-bold" :style="{'color' : this.primaryColor}">
        <base-arrow-back-icon @click="$router.go(-1)">{{ this.rtl ? rightArrowIcon : leftArrowIcon }}</base-arrow-back-icon>
        
         {{ $t('newAdminCourses.q&a_for_module') }} : {{ title }}
      <v-btn class="white--text ma-2" @click="newData = true" :color="this.YColor">
        <v-icon>{{this.plusIcon}}</v-icon>
        {{ $t('table.addNew') }}
      </v-btn>
      </h1>

    <Question v-for="question in questions" :key="question.id" :question="question" @refresh="handleDone" @remove="deleteType = 'question';deleteConfirm = question.id" @removeAnswer="openAnswerDelete" />

    <delete-courses-confirm
     v-if="deleteConfirm>=0"
      v-bind="{ id: deleteConfirm, isOpen: true, deleteType: deleteType }"
      @handleCancel="handleClose"
      @deleteQuestion="handleDelete"
      @deleteAnswer="handleremoveAnswer"
    ></delete-courses-confirm>
    <question-dialog
      v-if=" newData"
      @handleClose="handleDone"
    ></question-dialog>
  </v-row>
</template>

<script>
import Question from './Components/Question'
import deleteCoursesConfirm from "../../../../Components/confirmDelete"
import BaseArrowBackIcon from "../../../../../../core/Base/Arrows/BaseArrowBackIcon";
import QuestionDialog from './Components/QuestionDialog'
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Questions",

  components: { 
    Question,
    deleteCoursesConfirm,
    BaseArrowBackIcon,
    QuestionDialog
  },

  data() {
    return {
      questions: [],
      selectedModule: null,
      isEdit: false,
      newData: false,
      headers: [
        { text: this.$t('adminCourses.title'), value: "title" },
        { text: this.$t('adminCourses.moduleDescription'), value: "description" },
        { text: "", value: "actions", align: "center" },
      ],
      add_Traning: false,
      deleteConfirm: -1,
      deleteType: '',
      title: ''
    };
  },

  created () {
      this.init()
  },


  computed: {
    ...mapGetters("InstructorQuestions", ["getModule"]),

    ...mapState(["rtl"]), // to decide the direction of the arrow
  },

  methods: {
    ...mapActions("InstructorQuestions", ["fetchQuestions", "deleteQuestion", "deleteAnswer"]),
    
    init () {
      this.fetchQuestions(this.$route.params.id).then((res) => {
        console.log(res)
        if (this.getModule)
        if (this.getModule.module)
        if (this.getModule.module.questions) {
          this.questions = this.getModule.module.questions
          this.title = this.getModule.module.title
        }
      })
    },

    handleDelete (payload) {
      this.deleteQuestion(payload.id).then(() => {
        this.deleteConfirm = -1;
        this.handleDone();
      })
    },

    handleremoveAnswer (payload) {
      this.deleteAnswer(payload.id).then(() => {
        this.handleDone();
      })
    },

    handleDone () {
      this.handleClose();
      this.init()
    },

    handleClose () {
      this.isEdit = false;
      this.newData = false;
      this.deleteConfirm = -1;
    },

    openAnswerDelete(payload) {
      this.deleteType = 'answer'
      this.deleteConfirm = payload.id;

    }
    
  },
};
</script>
