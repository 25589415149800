<template>
  <v-dialog persistent v-model="dialog" max-width="800px">
    <v-card class="w-100">

      <v-form ref="form" lazy-validation v-on:submit.prevent>
        <v-layout row wrap class="question pa-5" v-for="(question, questionIndex) in questions" :key="questionIndex">
          <v-flex xs12 md4>
            <div class="grey--text mb-5">{{ $t('newAdminCourses.question') }}</div>
            <v-layout wrap justify-center align-center>
              <v-flex xs12 md9>
                <v-textarea v-model="question.content"
                auto-grow rows="2" :rules="titleRules"></v-textarea>
              </v-flex>

            </v-layout>
          </v-flex>
          <v-flex xs12 md7 class="pa-5">
              <div class="grey--text mb-1">{{ $t('newAdminCourses.answers') }}</div>
              <v-layout wrap justify-center align-center class="answer px-2" v-for="(answer, answerIndex) in question.answers" :key="answerIndex"> 
                <v-flex xs12 md6>
                  <v-text-field v-model="answer.content" :rules="titleRules"></v-text-field>
                </v-flex>
                <v-flex xs12 md3 class="px-2">
                  <v-select
                    v-model="answer.correct"
                    :items="correct"
                    item-text="status"
                    item-value="value"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md3 class="px-2">
                  <v-icon @click="cancelNewAnswer(questionIndex, answerIndex)" class="delete-icon">{{ closeIcon }}</v-icon>
                </v-flex>
                
              </v-layout>
            <v-layout column align-center justify-center>
              <base-button
                class="text-large"
                :options="{ block: false, color: darkColor}"
                @click="addNewAnswer(questionIndex)"
              >
                {{ $t('newAdminCourses.newAnswer') }}
              </base-button>
              <div class="mt-4" v-if="warn">{{ $t('newAdminCourses.answerWarning') }}</div>
            </v-layout>
          </v-flex>
          <v-flex xs12 md1>
            <v-icon x-large @click="cancelNewQuestion(questionIndex)" class="delete-icon">{{ closeIcon }}</v-icon>
          </v-flex>
        </v-layout>
        <v-layout>

        <v-layout column align-center justify-center class="mt-4">
          <base-button
            class="text-large px-9"
            :options="{ block: false, color: darkColor}"
            @click="addNewQuestion()"
          >
            {{ $t('newAdminCourses.newQuestion') }}
          </base-button>
        </v-layout>
        </v-layout>
        <v-row class="pa-6">
          <v-col cols="12" lg="6" sm="6">
            <base-button class="text-large" :options="{ block: true, isLoading: btnLoading, color: this.BColor }" @click="save()">
              {{ this.$t("form.save") }}
            </base-button>
          </v-col>

          <v-col cols="12" lg="6" sm="6">
            <base-button class="text-large" :options="{ block: true, color: this.YColor }" @click="$emit('handleClose')">
              {{ this.$t("form.cancel") }}
            </base-button>
          </v-col>

        </v-row>
        
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../../../core/Base/Buttons/BaseButton";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "QuestionDialog",

  data() {
    return {
      titleRules: [(v) => !!v || this.$t('required.field')],
      dialog: true,
      questions: [
        {
          content: '',
          type: 'oneChoice',
          answers: [
                    {
                      content: '',
                      correct: 0
                    }
                  ],
        }
      ],
      
      correct: [
        {
          status: this.$t('newAdminCourses.correct'),
          value: 1
        },
        {
          status: this.$t('newAdminCourses.wrong'),
          value: 0
        }
      ],
      btnLoading: false,
      warn: false,
      true: false
    };
  },

  methods: {
    ...mapActions("InstructorQuestions", ["addQuestion"]),

    close() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      this.$emit("handleCancel");
    },

    save() {
      if (!this.$refs.form.validate()) return;
      this.questions.forEach(question => {
        question.answers.forEach(answer => {
          if (answer.correct === 1) {
            this.warn = false;
            this.true = true;
          }
        })
      })
      if (!this.true) {
        this.warn = true;
      }
      
      this.btnLoading = true
      this.addQuestion({"module_id": this.$route.params.id, "questions": this.questions}).then(() => {
        this.btnLoading = false;
        if (!this.warn) {
          this.$emit('handleClose')
        }
      })
    },
    addNewAnswer (index) {
      let newAnswer = { content: '', correct: 0};
      this.questions[index].answers.push(newAnswer)
    },

    addNewQuestion () {
      let newQuestion = { type: 'oneChoice', content: '', answers: [{ content: '', correct: 0}]};
      this.questions.push(newQuestion)
    },

    cancelNewAnswer (questionIndex, answerIndex) {
      this.questions[questionIndex].answers.splice(answerIndex, 1);
    },

    cancelNewQuestion (questionIndex) {
      this.questions.splice(questionIndex, 1);
    },
  },
  components: { BaseButton },
};
</script>
<style lang="scss" scoped>
.question {
  padding: 1rem;
  border-bottom: 1px gray solid;
}
@media (max-width : 960px) {
  .question:nth-of-type(even) {
    background-color: lightgray;
  }
  .answer {
    padding: 1rem;
    margin-bottom: 2rem;
    border: 2px gray solid;
    border-radius: 5px;
  }
}
</style>
