<template>
  <v-flex xs12 md3 class="px-2">
    <v-icon @click="$emit('edit')" v-if="this.isEdit !== this.id" class="delete-icon">{{ editIcon }}</v-icon>
    <v-icon @click="$emit('remove')" v-if="this.isEdit !== this.id  && this.deleteBtn" class="delete-icon">{{ deleteIcon }}</v-icon>
    <v-icon @click="$emit('cancel')" v-if="this.isEdit === this.id" class="delete-icon">{{ closeIcon }}</v-icon>
    <v-icon @click="$emit('save')" v-if="this.isEdit === this.id" class="delete-icon">{{ answerIcon }}</v-icon>
  </v-flex>
</template>
<script>
export default {
  name: 'Buttons',
  props: [
    'isEdit',
    'id',
    'deleteBtn'
  ]
}
</script>
<style lang="scss" scoped>
.delete-icon:hover {
  color: darkgray;
  cursor: pointer;
}
</style>